import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

export default {
    fillable_by:FillableBy.Both,
    title:
        "Clinical Competency Checklist for Spectrum Quantum Perfusion System",
    sections: [
        // {...employeeinfo},
        {
            title: "Instruction(s) for Use",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Demonstrates knowledge of the availability and location of the instruction manual.",
                "Utilizes the instruction manual effectively for troubleshooting and operational guidance.",
                "Ensures team members are informed about accessing the instruction manual.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "E-Learning Enrollment/Completion",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Enrolled in and completed the e-learning module specific to the Spectrum Quantum system.",
                "Demonstrates understanding of key concepts covered in the e-learning module.",
                "Maintains records of e-learning completion.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Hardware Components",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Identifies all hardware components and their functions.",
                "Performs routine inspections to ensure components are operational.",
                "Assembles and connects hardware components appropriately.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Sterile Technologies",
            type: "table",
            heads:constants.tableHeads,
            rows: [
                "Demonstrates proper setup of sterile components to maintain sterility.",
                "Adheres to aseptic techniques during handling and operation.",
                "Replaces sterile components according to manufacturer guidelines.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Gauge Bar/Diagnostics/Capture-Sync",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Understands the function and interpretation of the gauge bar diagnostics.",
                "Demonstrates use of the capture-sync feature for data collection.",
                "Troubleshoots gauge bar diagnostic errors effectively.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "System Settings",
            type: "table",
            heads:constants.tableHeads,
            rows: [
                "Configures system settings based on procedural requirements.",
                "Demonstrates understanding of default and customizable settings.",
                "Monitors and adjusts settings during procedures as needed.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Pump Manager",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Operates the pump manager efficiently during procedures.",
                "Demonstrates understanding of pump settings and flow adjustments.",
                "Responds to alarms and errors in pump operations.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Pump Interventions: [Bbl, Lvl, Press]",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Understands and manages interventions related to bubble detection.",
                "Maintains appropriate levels during bypass procedures.",
                "Monitors and adjusts pressure settings to ensure patient safety.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Cardioplegia and Interlock Safeties",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Demonstrates proper use of cardioplegia system and associated settings.",
                "Tests interlock safety features prior to each procedure.",
                "Ensures compliance with safety protocols for interlocks.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Smart Modes",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Demonstrates activation and use of smart modes during procedures.",
                "Understands when and how to utilize specific smart modes for efficiency.",
                "Monitors and overrides smart modes when clinically indicated.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "VIPER and Toolbox Applications",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Accesses and operates VIPER application for monitoring and data management.",
                "Utilizes toolbox applications for diagnostics and configuration.",
                "Troubleshoots common issues using toolbox applications.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Device Connectivity",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Ensures proper connectivity with other medical devices and systems.",
                "Tests device connectivity prior to each procedure.",
                "Troubleshoots connectivity issues effectively.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Administrator Mode: Superusers",
            type: "table",
            heads:constants.tableHeads,
            rows: [
                "Accesses and navigates administrator mode for advanced configuration.",
                "Demonstrates ability to perform superuser tasks such as updating settings.",
                "Ensures compliance with security protocols for administrator access.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Emergency Procedures",
            type: "table",
            heads:constants.tableHeads,
            rows: [
                "Demonstrates knowledge of emergency shutdown procedures.",
                "Responds effectively to device malfunctions during critical scenarios.",
                "Communicates with the surgical team during emergencies.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Both)
        },
    ],
}